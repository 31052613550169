@import url(https://fonts.googleapis.com/css2?family=Aldrich&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#hero{display:grid;justify-content:center;height:100vh;grid-template-rows:2 auto;position:relative;z-index:1;background:linear-gradient(to top, rgba(0,0,0,0) 20%, #000),url(/static/media/skyline.27135e45.jpeg);background-size:cover;color:white}#hero #hero-con{display:grid;align-content:center;z-index:1;position:relative}#hero #profile{position:absolute;height:70vh;right:0;bottom:0;z-index:0}#hero #title{justify-self:center;font-size:4em;font-weight:400;margin:0;line-height:39.12px;font-family:'Aldrich', sans-serif}#hero #desc{margin:0;font-family:'Montserrat', sans-serif}#hero button{position:absolute;-webkit-transform:translateX(-50%);transform:translateX(-50%);bottom:0;left:50%;padding:1rem;font-size:large;margin-bottom:5px}

