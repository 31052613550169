@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
#hero {
  display: grid;
  justify-content: center;
  // background-color: brown;
  height: 100vh;
  grid-template-rows: 2 auto;
  position: relative;
  z-index: 1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)),
    url("../images/skyline.jpeg");
    // background-attachment: fixed;
  background-size: cover;
  color: white;
  #hero-con {
    display: grid;
    align-content: center;
    z-index: 1;
    position: relative;
  }
  #profile {
    position: absolute;
    height: 70vh;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  #title {
    justify-self: center;
    font-size: 4em;
    font-weight: 400;
    margin: 0;
    line-height: 39.12px;
    font-family: 'Aldrich', sans-serif;
  }
  #desc {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
  }
  button {
    position: absolute;
    transform: translateX(-50%);
    bottom: 0;
    left: 50%;
    padding: 1rem;
    font-size: large;
    margin-bottom: 5px;
  }
}
